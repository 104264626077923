<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Home Page
Description:
This component displays a hero section with a background video and dynamic text overlay for the home page.
It includes both desktop and mobile versions of the video and provides a placeholder image when the video
is not available or cannot be played. The text overlay cycles through messages, and users can scroll to
the video using a button. The video plays automatically when visible and pauses when it is not.

How to use it:
<Hero
  :hero-video="heroVideo"
  :hero-video-mobile="heroVideoMobile"
  :hero-text="heroText"
/>
-->
<template>
  <div class="image-container">
    <div class="overlay" />
    <div>
      <div
        v-if="showPlaceholder"
        class="video-placeholder"
      >
        <img
          :src="`${isMobile ? $cdn('hero/img_showreel_placeholder-mobile.jpg') : $cdn('hero/img_showreel_placeholder.scaled.jpg')}`"
          alt="Placeholder"
          class="bg-video"
        >
      </div>
      <video
        v-else
        v-once
        :key="videoKey"
        ref="videoElement"
        :poster="`${isMobile ? $cdn('hero/img_showreel_placeholder-mobile.jpg') : $cdn('hero/img_showreel_placeholder.scaled.jpg')}`"
        class="bg-video"
        loop
        muted
        playsinline
        preload="auto"
        alt="Descriptive Video Text"
        @error="videoError"
        @canplay="markPlayable"
        @playing="markPlayable"
      >
        <source
          v-for="videoUrl in (isMobile? heroVideoMobile:heroVideo)"
          :key="videoUrl"
          :src="videoUrl"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>

    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <span
        ref="heroOverlayText"
        :key="currentText"
        class="hero-overlay-text"
      >{{ currentText }}</span>
    </transition>

    <div class="bottom-button-container">
      <a
        href="#"
        @click.prevent="scrollToVideo"
      >
        <div
          ref="heroNextIcon"
          class="hero-next-icon"
        >
          <img
            class="rotate-infinite"
            :src="$cdn('hero/hero-next-text.png')"
            alt="Next text icon"
          >
          <img
            class="hero-next-icon hero-next-arrow"
            :src="$cdn('hero/hero-arrow-down.svg')"
            alt="Next text icon"
          >
        </div>
      </a>
    </div>
  </div>
</template>


<script setup lang="ts">
import { computed, inject, onMounted, ref, onUnmounted}  from 'vue';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { uuid } from 'vue3-uuid';

// Register GSAP plugins
gsap.registerPlugin(ScrollToPlugin);

const heroVideo = ref(
  process.env.APP_ENV == 'local' ?
    [
      'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
    ]
    :
    [
      'https://cloudinary.alphapod.com/alphapod/video/upload/v1711073548/alphapod_website/website_2024/assets/video/ap_showreel_2305.av1.mp4',
      'https://cloudinary.alphapod.com/alphapod/video/upload/v1711073548/alphapod_website/website_2024/assets/video/ap_showreel_2305.h264.mp4',
    ]
);

const heroVideoMobile = ref(
  process.env.APP_ENV == 'local' ?
    [
      'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4',
      'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
    ]
    :
    [
      'https://cloudinary.alphapod.com/alphapod/video/upload/v1741670424/alphapod_website/website_2024/assets/video/mobile_ap_showreel_2305.h264.480p.mp4',
      'https://cloudinary.alphapod.com/alphapod/video/upload/v1711073548/alphapod_website/website_2024/assets/video/mobile_ap_showreel_2305.av1.mp4',
      'https://cloudinary.alphapod.com/alphapod/video/upload/v1711073548/alphapod_website/website_2024/assets/video/mobile_ap_showreel_2305.h264.mp4',
    ]
);

// Refs
const heroNextIcon = ref(null);
const heroOverlayText = ref(null);
const videoElement = ref<HTMLVideoElement | null>(null);
const videoKey = ref(uuid.v4());

const showPlaceholder = ref(false);
const videoPlayable = ref(false);
const playTimeout = ref<any | null>(null);
const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
// Text cycling variables
const texts = [ mainSite.value ? 'Digital experiences that delight users, impact lives, and change the world.' : 'Empowering your business to stay ahead for the future.'];
const currentText = ref(texts[0]);
let textIndex = 0;
let textInterval: ReturnType<typeof setInterval> | undefined;

// Helper Functions
const scrollToVideo = () => {
  if (videoElement.value) {
    const videoPosition = videoElement.value.getBoundingClientRect();
    const videoTop = videoPosition.top + window.scrollY - window.innerHeight;
    gsap.to(window, { scrollTo: { y: -1 * videoTop, autoKill: false }, duration: 1 });
  }
};

const isMobile = ref(window.innerWidth <= 820);

const handleResize = () => {
  const state = window.innerWidth <= 820;
  if (state !== isMobile.value) {
    isMobile.value = state;
    videoKey.value = uuid.v4();
  }
};

const cycleText = () => {
  textIndex = (textIndex + 1) % texts.length; // Cycle through texts
  currentText.value = texts[textIndex]; // Update the current text
};

const startTextCycling = () => {
  stopTextCycling(); // Stop any existing interval to avoid duplicates
  textInterval = setInterval(cycleText, 3000); // Start a new interval
};

const stopTextCycling = () => {
  if (textInterval !== undefined) {
    clearInterval(textInterval);
    textInterval = undefined; // Clear the interval ID by setting it to undefined
  }
};

function videoError(){
  console.log('Video failed to load');
}

const observer = ref<IntersectionObserver | null>(null);

// function applyEffect() {
//   const urlParams = new URLSearchParams(window.location.search);
//   const blurIntensity = urlParams.get('glassmorphism') || 0; // Default blur value

//   if (CSS.supports('backdrop-filter', 'blur(10px)')) {
//     const elements = document.querySelectorAll('.overlay');
//     elements.forEach(element => {
//       element.style.backdropFilter = `blur(${blurIntensity}px)`;
//     });
//   }
// }
// Run the function on page load
// document.addEventListener('DOMContentLoaded', applyEffect);

// Lifecycle hooks
onMounted(() => {
  // window.addEventListener('orientationchange', setFullHeight);
  startTextCycling(); // Moved here for better visibility
  if (videoElement.value) {
    videoElement.value.play().catch(_ => {
      console.log('Video failed to load');
    });
    setPlayTimeout();
  }

  window.addEventListener('resize', handleResize);
  handleResize();

  observer.value = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (videoElement.value) {
        if (entry.isIntersecting) {
        // Check if the video is ready to play before calling play()
          if (videoElement.value.readyState >= 3) { // '3' means 'can play through'
            videoElement.value.play().catch((error) => {
              console.log('Play request failed:', error);
            });
          } else {
          // Listen for the loadeddata event to play once ready
            videoElement.value.addEventListener('loadeddata', () => {
              videoElement.value.play().catch((error) => {
                console.log('Play request failed:', error);
              });
            }, { once: true }); // Ensure the event is only listened to once
          }
        } else {
          videoElement.value.pause();
        }
      }
    });
  }, { threshold: 0.2 });

  if (videoElement.value) {
    observer.value.observe(videoElement.value);
  }
});

onUnmounted(() => {
  stopTextCycling();
  window.removeEventListener('resize', handleResize);

  if (videoElement.value && observer.value) {
    observer.value.unobserve(videoElement.value);
  }
});

const setPlayTimeout = () => {
  if (isMobile.value) {
    if (playTimeout.value) {
      clearTimeout(playTimeout.value);
    }

    playTimeout.value = setTimeout(() => {
      if (!videoPlayable.value) {
        showPlaceholder.value = true;
      }
    }, 3000);
  }
};

const markPlayable = () => {
  videoPlayable.value = true;
  clearTimeout(playTimeout.value);
};


</script>


<style scoped>

.image-container {
  position: relative;
}

.overlay{
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background-color: rgba(0,0,0,0.4) ;
  max-width: 3840px;
}

.placeholder {
  height: 100vh;
  object-fit: cover;
  position: relative;
  width: 100vw;
  z-index: -1;
}

.bg-image {
  width: 100vw;
  height: auto;
  display: block;
}

.bg-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  max-width: 3840px;
}

.bg-image.is-loading {
  background-color: #ccc;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.hero-overlay-text {
  position: absolute;
  font-family: 'Inter' sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 84px;
  letter-spacing: -3px;
  text-align: left;
  bottom: 17%;
  left: 17%;

  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 879px;
  padding: 0;
  overflow: hidden
}

.hero-next-icon {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 48px;
}

.hero-next-arrow {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust positioning to truly center the element */
  height: 18px;
  width: auto; /* Width will adjust based on the content */
  z-index: 1000; /* Ensure it's above other content; adjust as necessary */
}


/* next button infinite rotation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-infinite {
  animation: rotate 6s linear infinite;
}

@media (max-width: 1024px) {
  .hero-overlay-text {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter' sans-serif;
    left: 50%;
    bottom: 160px;
    transform: translate(-50%);
    font-size: 48px;
    font-weight: 700;
    line-height: 58.09px;
    letter-spacing: -1px;
    text-align: left;
    padding: 0 48px;

  }
}

@media screen and (max-width: 820px) {
  .hero-overlay-text {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter' sans-serif;
    left: 50%;
    bottom: 168px;
    transform: translate(-50%);
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: left;
    padding: 0 80px;
  }

  .bottom-button-container{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .hero-overlay-text {
    padding: 0 32px;
  }
}

</style>
