<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component provides a banner to inform users of the cookie consent policy.
It includes a button to accept all cookies, a button to manage cookie preferences, and a modal to display cookie policy.

How to use it:
<PrivacyPolicyBanner
  :show-banner="showBanner"
  @strictly-necessary="handleStrictlyNecessary"
  @accept-all="handleAcceptAll"
/>
-->
<template>
  <div class="main-container">
    <div :class="`layout-container ${!showBanner ? 'hide' : ''}`">
      <div class="left-section">
        <h3>Cookie consent</h3>
        <p>This website uses cookies to enhance your browsing experience and provide personalized content. <a href="/cookie-policy/">Learn more</a></p>
      </div>
      <div class="right-section">
        <button
          class="btn-secondary"
          @click="onClickStrictlyNecessary"
        >
          Manage my preferences
        </button>
        <button
          class="btn-primary"
          @click="onClickAcceptAll"
        >
          Accept all
        </button>
      </div>
    </div>
    <transition name="fade">
      <CookieModal
        v-if="showCookieModal"
        @close-cookie-modal="handleModalClick"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import CookieModal from './CookieModal.vue';

const showBanner = ref(false);
const showCookieModal = ref(false);

const emit = defineEmits(['open-cookie-modal']);

// Utility function to get localStorage value
function getLocalStorageItem(name: string): boolean {
  return localStorage.getItem(name) === 'true';
}

// Utility function to set localStorage item
function setLocalStorageItem(name: string, value: boolean) {
  localStorage.setItem(name, JSON.stringify(value));
}

onMounted(() => {
  watch(
    () => getLocalStorageItem('cookiesAccepted'),
    (value) => {
      showBanner.value = value !== true;
    },
    { immediate: true }
  );

  watch(
    () => showCookieModal.value,
    (value) => {
      document.body.style.overflow = value ? 'hidden' : '';
    },
    { immediate: true }
  );
});

function handleModalClick(data: any) {
  showCookieModal.value = false;
  if (data != null) {
    setLocalStorageItem('essential_consent', data[0].state);
    setLocalStorageItem('performance_consent', data[1].state);
    setLocalStorageItem('analytics_consent', data[2].state);
    setLocalStorageItem('social_network_consent', false);

    setLocalStorageItem('cookiesAccepted', true);

    console.log('Cookies accepted:', getLocalStorageItem('cookiesAccepted'));
    showBanner.value = false;
  } else {
    setLocalStorageItem('essential_consent', false);
    setLocalStorageItem('performance_consent', false);
    setLocalStorageItem('analytics_consent', false);
    setLocalStorageItem('social_network_consent', false);

    setLocalStorageItem('cookiesAccepted', false);
  }
}

const onClickStrictlyNecessary = () => {
  showCookieModal.value = true;
  showBanner.value = false;
};

const onClickAcceptAll = () => {
  setLocalStorageItem('essential_consent', true);
  setLocalStorageItem('performance_consent', true);
  setLocalStorageItem('analytics_consent', true);
  setLocalStorageItem('social_network_consent', true);

  setLocalStorageItem('cookiesAccepted', true);
  showBanner.value = false;
};
</script>



<style scoped>

.main-container {
  z-index: 9999;
}

.layout-container {
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding: 24px 64px;
  backdrop-filter: blur(8px);
  border-top: #DCDCDC 1px solid;
  z-index: 999;
}

.hide {
  display: none;
}

.left-section {
  display: flex;
  flex-direction: column;
}

.left-section h3 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.left-section p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.left-section a {
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

.right-section {
  display: flex;
  gap: 16px;
  flex-direction: row;
}
.btn-primary {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background-color: #007AFF;
  color: white;
}

.btn-secondary {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background: #FFF;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .layout-container {
    flex-direction: column;
    padding: 32px 24px;
  }

  .left-section {
    margin-bottom: 64px;
  }

  .right-section {
    flex-direction: column-reverse;
    gap: 12px;
  }

}
</style>
