// import he from 'he';
import axios from 'axios';
import { HRMUrl, sendEmailUrl } from './constant';

export const getScreenSize = () => {
  const width = window.innerWidth;
  if (width < 480) {
    return 'mobile';
  } else if (width >= 480 && width <= 768) {
    return 'tablet';
  } else if (width > 768) {
    return 'desktop';
  }

  return null;
};

// export const removeHtmlString = (htmlString: string) => {
//   const unescapedHtml = he.decode(htmlString);
//   const plainText = unescapedHtml.replace(/<[^>]*>/g, '');

//   return plainText;
// };

export const arraysAreEqual = (array1: Array<any>, array2: Array<any>) => {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

//https://stackoverflow.com/a/49448231
type BROWSER_ENUM =
  'EDGE' |
  'INTERNET_EXPLORER' |
  'FIRE_FOX' |
  'OPERA' |
  'UC_BROWSER' |
  'SAMSUNG_BROWSER' |
  'CHROME' |
  'SAFARI' |
  'UNKNOWN';


const testUserAgent = (regexp: RegExp): boolean => regexp.test(window.navigator.userAgent);

export function detectBrowser(): BROWSER_ENUM {
  switch (true) {
    case testUserAgent(/edg/i): return 'EDGE';
    case testUserAgent(/trident/i): return 'INTERNET_EXPLORER';
    case testUserAgent(/firefox|fxios/i): return 'FIRE_FOX';
    case testUserAgent(/opr\//i): return 'OPERA';
    case testUserAgent(/ucbrowser/i): return 'UC_BROWSER';
    case testUserAgent(/samsungbrowser/i): return 'SAMSUNG_BROWSER';
    case testUserAgent(/chrome|chromium|crios/i): return 'CHROME';
    case testUserAgent(/safari/i): return 'SAFARI';
    default: return 'UNKNOWN';
  }
}

export const checkLang = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return langParam == 'zh-hans' ? 'zh-hans' : 'en';
};


// EMAIL HELPER

export const sendEmailWithAttachments = async (recaptchaToken: string, message: string, fileInputElements: HTMLInputElement | HTMLInputElement[] | undefined, isJobForm: boolean = false, subject?: string): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('recaptchaToken', recaptchaToken);
    // Append email data to formData
    if (isJobForm) {
      const title = subject ? `Job Application - ${subject}` : 'Application from job application form';
      // Application from job form
      formData.append('to', 'email1');
      formData.append('subject', title);
    } else {
      const title = subject ? `${subject} x Alphapod` : 'Application from contact us form';
      // Application from contact us form
      formData.append('to', 'email2');
      formData.append('subject', title);
    }

    formData.append('message', message);

    // Append files to formData if available
    if (fileInputElements) {
      if (!Array.isArray(fileInputElements)) {
        fileInputElements = [fileInputElements];
      }
      fileInputElements.forEach(input => {
        const files = input.files;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[]', files[i], files[i].name);
          }
        }
      });
    }

    // Make POST request to custom WordPress API endpoint with formData
    const response = await axios.post(sendEmailUrl(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle response
    if (response.data.success) {
      console.log('Email sent successfully');
    } else {
      console.error('Failed to send email');
    }
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const submitToHRM = (content: JobApplicationHRM) => {
  const formData = new FormData();

  // Append non-file fields
  formData.append('name', content.name);
  formData.append('email', content.email);
  formData.append('position_applied', content.positionApplied);
  formData.append('department_applied', content.departmentApplied);
  formData.append('heard_about_us', content.heardAboutUs);
  formData.append('apply_reason', content.applyReason);

  // Append files if they exist
  if (content.resumeFile instanceof HTMLInputElement && content.resumeFile.files?.length) {
    formData.append('resume_file', content.resumeFile.files[0]);
  } else {
    console.warn('No valid resume file provided.');
  }

  if (content.supportingFile instanceof HTMLInputElement && content.supportingFile.files?.length) {
    formData.append('supporting_file', content.supportingFile.files[0]);
  } else {
    console.warn('No valid supporting file provided.');
  }

  const url = HRMUrl();
  if (!url) {
    return Promise.reject(new Error('HRM API URL is not defined'));
  }

  // Submit the form data
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Navbar path splitter

export function checkSlash(path: string) {
  const temp = path.split('/');
  return '/' + temp.filter((item) => item !== '').join('/');
}

/**
 * Constructs an email message from project enquiry data.
 * @param {Object} enquiryData - The project enquiry data.
 * @returns {string} The formatted email message.
 */
export const constructEmailMessage = (enquiryData: any) => {
  const {
    name,
    email,
    company,
    organization,
    role,
    contact_number_country_code,
    contact_number,
    contact_purpose,
    project_status,
    budget,
    ideal_go_live_date,
    project_description,
    project_description2,
    attachment_file,
  } = enquiryData;

  let contact = '—';
  if (contact_number) {
    contact = `${contact_number_country_code}${contact_number}`;
  }

  // Safely construct project status as an array of escaped items
  let projectStatus: string[] = [];
  if (project_status) {
    projectStatus = project_status.map((status: string) => status);
  }

  // Construct the JSON object for the email message with properly escaped values
  const emailMessage = {
    name: `${name}, ${role} at ${organization}`,
    email: email,
    contact_number: contact,
    organisation_type: company,
    contact_purpose: contact_purpose,
    project_components: projectStatus.length > 0 ? projectStatus : '—',
    budget: budget,
    ideal_go_live_date: ideal_go_live_date,
    project_description: project_description,
    additional_notes: project_description2 ? project_description2 : '—',
    attachment: attachment_file ? 'Yes' : 'No',
  };

  // Return the JSON string
  return JSON.stringify(emailMessage);
};


/**
 * This function sanitizes input by escaping HTML characters, ensuring safety in environments
 * where no HTML tags are intended, such as plain text emails. This prevents security risks,
 * like injection attacks. Note: Previously, this function led to unintended 'garbage data'
 * appearing in the email body due to over-sanitization.
 */
const escapeHTML = (input: string): string => {
  return String(input)
    .replace(/&/g, '&amp;')  // Escape '&'
    .replace(/</g, '&lt;')   // Escape '<'
    .replace(/>/g, '&gt;')   // Escape '>'
    .replace(/"/g, '&quot;') // Escape '"'
    .replace(/'/g, '&#039;'); // Escape "'"
};

export const constructJobApplicationMessage = (enquiryData: any) => {
  const {
    name,
    email,
    contact_number,
    contact_number_country_code,
    position_applied,
    department_applied,
    heard_about_us,
    apply_reason,
  } = enquiryData;

  // Construct contact number safely
  let contactNumber = '-';
  if (contact_number) {
    contactNumber = `${contact_number_country_code}${contact_number}`;
  }

  // Construct the JSON object for the email message
  const emailMessage = {
    name: name,
    position_applied: position_applied,
    email: email,
    contact_number: contactNumber,
    heard_about_us: heard_about_us,
    reason_for_applying: apply_reason,
    attachment: 'Yes',
  };

  // Return the JSON string
  return JSON.stringify(emailMessage);
};

import { ref } from 'vue';
import { JobApplicationHRM } from './model';

export const fadeInEffect = (element: string) => {
  const selector = document.querySelector(element);

  if (selector) {
    // Set initial state
    selector.style.opacity = '0';

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('Element is visible:', entry.target);
            entry.target.classList.add('animate__animated', 'animate__fadeIn', 'animate__faster');

            // Remove observer after animation starts to avoid running it again
            observer.unobserve(entry.target);

            // Ensure it stays visible after animation
            entry.target.addEventListener('animationend', () => {
              entry.target.style.opacity = '1'; // Keep it visible
              entry.target.classList.remove('animate__animated', 'animate__fadeIn');
            });
          }
        });
      },
      {
        threshold: 0.25, // Trigger when 25% of the element is visible
      }
    );

    observer.observe(selector);
  }
};



export const scrollTo = (id: string, offsetY?: number) => {
  const element = document.getElementById(id);
  const topNav = document.getElementById('top-nav');
  const topNavHeight = topNav!.getBoundingClientRect().height!;
  const temp = offsetY ?? topNavHeight;
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - temp;
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
};

export const generateRandomClassName = () => {
  //create a random class name with prefic cls-
  return `cls-${Math.random().toString(36).substr(2, 5)}`;
};

export function useTimer(
  duration: number, // Timer duration in seconds
  onComplete: () => void // Callback to trigger when the timer completes
) {
  const timeRemaining = ref(duration);
  const timerInterval = ref<number | null>(null);
  const isRunning = ref(false);

  // Computed properties for minutes and seconds
  const minutes = ref(Math.floor(timeRemaining.value / 60).toString().padStart(2, '0'));
  const seconds = ref((timeRemaining.value % 60).toString().padStart(2, '0'));

  // Function to start the timer
  const startTimer = () => {
    if (isRunning.value) return; // Prevent multiple intervals
    isRunning.value = true;

    timerInterval.value = setInterval(() => {
      if (timeRemaining.value > 0) {
        timeRemaining.value -= 1;
        // Update minutes and seconds
        minutes.value = Math.floor(timeRemaining.value / 60).toString().padStart(2, '0');
        seconds.value = (timeRemaining.value % 60).toString().padStart(2, '0');
      } else {
        stopTimer();
        onComplete(); // Call the callback function when timer ends
      }
    }, 1000);
  };

  // Function to stop the timer
  const stopTimer = () => {
    if (timerInterval.value !== null) {
      clearInterval(timerInterval.value);
      timerInterval.value = null;
      isRunning.value = false;
    }
  };

  // Function to reset the timer
  const resetTimer = () => {
    stopTimer();
    timeRemaining.value = duration;
    minutes.value = Math.floor(timeRemaining.value / 60).toString().padStart(2, '0');
    seconds.value = (timeRemaining.value % 60).toString().padStart(2, '0');
  };

  return {
    timeRemaining,
    minutes,
    seconds,
    isRunning,
    startTimer,
    stopTimer,
    resetTimer,
  };
}

export async function fetchFeaturedWorks(site_id: number, icon_only = false) {
  const url = `/wp-json/api/v1/featured-work?site_id=${site_id}${icon_only ? '&icon_only=true' : ''}`;

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching featured works:', error);
  }
  return null;
}

export async function fetchShowcaseWorks(site_id:number){

  // get url params from url
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('reset_cache');

  const url = `/wp-json/api/v1/showcase-works?site_id=${site_id}&mode=works${langParam ? '&reset_cache=true' : ''}`;

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching showcase works:', error);
  }
  return null;
}
