<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Works Page

Description:
This component is responsible for rendering the list of clients and their logos. It uses a Swiper component to
display the logos in a grid format. The component also includes a tab button for selecting different categories of clients.

How to use it:
<ClientIconsLight />
-->
<template>
  <div
    id="client-icons"
    class="layout-container"
  >
    <div class="inner-container">
      <!-- title -->
      <h1
        class="title"
      >
        Welcome to the <span class="strikethrough">brag wall</span> wall of fame.
      </h1>
      <div v-if="isTablet">
        <Swiper
          slides-per-view="auto"
          :free-mode="true"
        >
          <SwiperSlide
            v-for="(category, index) in categories"
            :key="`category-${index}`"
            class="category-button"
            :class="{ 'is-selected': selectedCategory === category }"
            @click="selectedCategory = category"
          >
            {{ category }}
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        v-else
        class="categories"
      >
        <button
          v-for="(category, index) in categories"
          :key="`category-${index}`"
          class="category-button"
          :class="{ 'is-selected': selectedCategory === category }"
          @click="onSelectCategory(category)"
        >
          {{ category }}
        </button>
      </div>
      <div
        v-if="isTablet"
        :key="categorykey"
        class="brands-grid-container"
      >
        <Swiper
          :modules="[Pagination]"
          :slides-per-view="1"
          space-between="24"
          :pagination="{ clickable: true, el: '.custom-swiper-pagination' }"
          :auto-height="true"
          class="mySwiper"
        >
          <SwiperSlide
            v-for="(group, index) in groupedBrands"
            :key="index"
          >
            <div :class="group.length > 2 ? 'grid-2x2' : 'grid-2x1'">
              <div
                v-for="(brand, idx) in group"
                :id="brand.slug"
                :key="`brand-${idx}`"
                class="grid-item"
              >
                <div class="brand-icon">
                  <img
                    :src="brand.img"
                    :alt="brand.name"
                    loading="lazy"
                  >
                  <div class="swiper-lazy-preloader" />
                </div>
                <div
                  class="brand-description"
                  :data-href="brand.feature_gallery"
                  @click="handleClick($event, brand.feature_gallery)"
                  v-html="brand.feature_detail"
                />
                <p class="brand-tag">
                  {{ brand.tags.join(', ') }}
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="custom-swiper-pagination" />
      </div>
      <div
        v-else
        class="brands-grid"
      >
        <TransitionGroup>
          <div
            v-for="brand in filteredBrands"
            :id="brand.slug"
            :key="`${brand.id}`"
            class="brand"
          >
            <div class="brand-icon">
              <img
                :src="brand.img"
                :alt="brand.name"
              >
            </div>
            <div
              class="brand-description"
              @click="handleClick($event, brand.feature_gallery)"
              v-html="brand.feature_detail"
            />
            <p class="brand-tag">
              {{ brand.tags.join(', ') }}
            </p>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <transition name="fade">
      <gallery-modal
        v-if="showGalleryModal"
        :images="galleryImages"
        :start-at="galleryStartAt"
        @close-modal="showGalleryModal = false"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, type Ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import GalleryModal from './GalleryModal.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import { uuid } from 'vue3-uuid';
import { Pagination } from 'swiper/modules';

gsap.registerPlugin(ScrollToPlugin);

const showGalleryModal = ref(false);
const galleryImages = ref(undefined);
const emit = defineEmits(['modal-toggle']);

const categorykey = ref(uuid.v4());

const galleryStartAt = ref(0);

let scrollPosition = 0;

function openModal() {
  scrollPosition = window.pageYOffset;  // Save the scroll position
  document.body.style.overflow = 'hidden';
  // document.body.style.position = 'fixed';  // Optional: for better handling on iOS devices
  document.body.style.top = `-${scrollPosition}px`;
}

function closeModal() {
  galleryImages.value = undefined;
  document.body.style.overflow = 'auto';
  // document.body.style.position = '';
  window.scrollTo(0, scrollPosition);  // Restore the scroll position
}

function handleClick(event: any, gallery: any) {
  const { target } = event;
  if (target.tagName === 'A' && target.getAttribute('href').includes('#gallery')) {
    const id = target.getAttribute('href').replace('#gallery-', '');
    if (id) {
      galleryStartAt.value = id - 1;
    }
    event.preventDefault();
    galleryImages.value = gallery;
    showGalleryModal.value = true;
  }
}

function onSelectCategory(category: string) {
  selectedCategory.value = category;
  categorykey.value = uuid.v4();
}

function onResize() {
  isTablet.value = window.innerWidth <= 820;
}

function scrollToElement(){
  // Convert the value to lowercase for case-insensitive comparison
  const lowercaseValue = props.query.toLowerCase();

  if (lowercaseValue.length > 0) {
  //remove params from url
    const params = new URLSearchParams(window.location.search);

    params.delete('goto');
    window.history.replaceState({}, '', window.location.pathname + lowercaseValue);

    let selector = document.getElementById(lowercaseValue);
    if (isTablet.value) {
      selector = document.getElementById('client-icons');
    }

    gsap.to(window, {
      duration: 0.5,
      scrollTo: { y: '#client-icons', autoKill: false },
      onComplete: () => {
        if (selector) {
          const offset = 200;
          const elementPosition = selector.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
      }
    });

    // Find a category that matches the lowercase value, also converted to lowercase
    const matchingCategory = categories.value.find(category => category.toLowerCase() === lowercaseValue);
    // If a matching category is found, use it; otherwise, default to 'All'
    selectedCategory.value = matchingCategory || 'All';
  }
}

window.addEventListener('DOMContentLoaded', scrollToElement);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);

  watch(showGalleryModal, (value) => {
    emit('modal-toggle', value);
    if (value) {
      openModal();
    }else {
      closeModal();
    }
  }, {
    immediate: true
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

// Extended DataItem interface to include new fields
interface DataItem {
  title: string;
  description: string;
  image: string;
  logo: {
    url: string;
    alt: string;
  };
  feature_detail: string;
  feature_gallery: any[];
  slug: string;
  testimonies: any[];
  categories: string[];
  tags: string[];
}

interface ProcessedBrand {
  name: string;
  category: string[];
  img: string;
  alt: string;
  description: string;
  feature_detail: string;
  feature_gallery: any[];
  slug: string;
  testimonies: any[];
  tags: string[];
  id: string;
}

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  query: {
    type: String,
    default: '',
  }
});

const process = (value: any): ProcessedBrand => {
  return {
    name: value.title,
    description: value.description,
    feature_detail: value.feature_detail,
    feature_gallery: value.feature_gallery,
    slug: value.slug,
    testimonies: value.testimonies,
    category: value.categories || ['Other'],
    tags: value.tags || [],
    img: getImageUrl(value.logo),
    alt: value.logo?.alt ?? 'image',
    id: '',
  };
};

function getImageUrl(logo: any): string {
  return logo && typeof logo === 'object' && 'url' in logo ? logo.url : '';
}

const brands: Ref<ProcessedBrand[]> = computed(() => props.data.map(process));

// Compute categories from brands
const categories = computed(() => {
  const allCategories = brands.value.flatMap(brand => brand.category);
  const uniqueCategories = new Set(allCategories);
  return ['All', ...Array.from(uniqueCategories)];
});

const selectedCategory = ref('All');

const isTablet = ref(window.innerWidth <= 820);

// Filter brands based on the selected category
const filteredBrands = computed(() => {
  // First filter the brands based on the selected category
  let result = brands.value;
  if (selectedCategory.value !== 'All') {
    result = brands.value.filter(brand => brand.category.includes(selectedCategory.value));
  }

  // Then sort the resulting array alphabetically by brand name
  result.sort((a, b) => a.slug.localeCompare(b.slug));
  //append unique id to each of the brands in the list
  result.forEach((brand) => {

    brand.id = uuid.v4();
    // brand.id = brand.slug;
  });

  return result;
});

const groupedBrands = computed(() => {
  if (!isTablet.value) {
    return []; // Return an empty array if not in mobile view
  }
  const groups = [];
  const items = filteredBrands.value;
  for (let i = 0; i < items.length; i += 4) {
    groups.push(items.slice(i, i + 4));
  }
  return groups;
});
</script>


<style scoped>

.thumbnailfade-leave-active,
.thumbnailfade-enter-active {
  transition: all 0.4s ease;
}

.thumbnailfade-enter-active {
  transition-delay: 0.4s;
}

.thumbnailfade-enter,
.thumbnailfade-leave-to {
  opacity: 0;
}

/* Transition classes for fade effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.v-enter-from {
  opacity: 0;
  scale: 0;
}
.v-enter-to {
  opacity: 1;
  scale: 1;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: all 0.7s;
}
.v-leave-active { position: absolute; }
.v-leave-from { opacity: 1; scale: 1; }
.v-leave-to { opacity: 0; scale: 0; }

.layout-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inner-container {
  max-width: 1280px;
  padding-top: 160px;
  padding-bottom: 120px;
  align-items: center;
}

.title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  padding-bottom: 120px;
}

.strikethrough {
  text-decoration: line-through;
  /* You can add other styles like color, font-weight, etc. */
}

.swiper {
    width: 100%;
    margin-bottom: 32px;
  }

.subtitle {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 120px;
}

.categories {
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
  white-space: nowrap;
}

.category-button {
  height: 64px;
  border-radius: 12px;
  background: #F0F0F0;
  color: black;
  border: none;
  padding: 16px 24px;
  margin: 0 5px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  outline: none; /* Removes the outline to match the design */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.category-button.is-selected {
  border-radius: 12px;
  background: #242424;
  color: white;
}

/* Optionally, change the hover state for additional user feedback */
.category-button:hover {
  background-color: #e0e0e0; /* Lighter background on hover */
}

.category-button.is-selected:hover {
  background: #242424;
}


.brands-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.brand {
  display: flex;
  flex-direction: column;
  padding: 32px;
  aspect-ratio: 1;
  width: 420px;
  margin: 0.5px;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0 0 0 1px #E6E6E6;
}

.brand-icon {
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
  padding-bottom: 24px;
}

.brand-icon img{
  height: 100%;
  max-height: 48px;
  width: auto;
}

.brand-description {
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  flex-grow: 1;
  text-align: start;
  line-height: 32px;
  padding-bottom: 24px;
}

.brand-description a{
  color: blue;
  text-decoration: underline;
}

.brand-tag{
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
  text-align: start;
}

@media screen and (max-width: 1220px) {
  .categories {
    justify-content: start;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .category-button:first-of-type {
    margin-left: 24px;
  }

  .category-button:last-of-type {
    margin-right: 24px;
  }
}

@media screen and (max-width: 820px) {
  .title {
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .swiper {
    width: 100vw;
    padding: 0 24px;
  }

  .categories {
    display: flex;
    flex-wrap: none;
    width: fit-content;
    height: auto;
    padding-bottom: 32px;
    margin-left: 0;
  }

  /* Style to hide scrollbar on Webkit browsers like Chrome and Safari */
  .categories::-webkit-scrollbar {
    display: none;
  }

  .category-button:first-of-type {
    margin-left: 0;
  }

  .category-button:last-of-type {
    margin-right: 0;
  }

  .inner-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .category-button {
    width: fit-content;
    height: 48px;
    display: inline-flex;
    align-items: center;
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    padding: 12px 24px;
    white-space: nowrap;
    border-radius: 10px;
  }

  .category-button.is-selected {
    border-radius: 10px;
  }

  .grid-2x2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .grid-2x1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .brands-grid-container {
    position: relative;
  }

  .grid-item {
    box-shadow: 0 0 0 1px #E6E6E6;
    margin: 0.5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .brands-grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 16px;
  }

  .brand-icon img{
    height: 100%;
    max-height: 24px;
  }

  .brand-description{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .brand-tag{
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .brand {
    padding: 20px;
    width: 100%;
    height: 100%;
  }
}

</style>
